<template>

  <div>
    <category-list-add-new
      :is-add-new-category-sidebar-active.sync="isAddNewCategorySidebarActive"
      @refetch-data="refreshTable"
    />
    <category-list-edit
      :is-edit-category-sidebar-active.sync="isEditCategorySidebarActive"
      :data-category="categoryData"
      :current-array-description="currentArrayDescription"
      :current-count-description="currentCountDescription"
      @refetch-data="refreshTable"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('category_create')"
                variant="primary"
                @click="isAddNewCategorySidebarActive = true"
              >
                <span class="text-nowrap">Add category</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refCategoryTable"
          class="position-relative"
          :items="fetchCategory"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'detail-product', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>

          <!-- Column: CreatedAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.created_at) }}
            </span>
          </template>
          <!-- Column: Region -->
          <template #cell(region_id)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.region_id ? data.item.region_id.toUpperCase() : '' }}</span>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('category_update')"
              @click="showSideBarEdit(data.item.id)"
            >
              Edit
            </b-link>
            <b-link v-if="$can('tracking_read')" @click="showModalTracking(data.item.id)">
              Tracking
            </b-link>
          </template>

        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <tracking-status
      :is-show-modal-tracking.sync="isShowModalTracking"
      :target="target"
      :actor="actor"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BPagination, BOverlay,
} from 'bootstrap-vue'
import CategoryListAddNew from '@/components/categoryManager/CategoryListAddNew.vue'
import CategoryListEdit from '@/components/categoryManager/CategoryListEdit.vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import userCategoryList from '@/views/categoryManager/category'
import store from '@/store'
import Category from '@/entity/Category'
import { mapGetters } from 'vuex'
import TrackingStatus from '../modal/TrackingStatus.vue'

export default {
  components: {
    BOverlay,
    TrackingStatus,
    CategoryListEdit,
    CategoryListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    showModalTracking(id) {
      this.target = `storeConnection:category:${id}`
      this.actor = ''
      this.isShowModalTracking = true
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    showSideBarEdit(id) {
      store.dispatch('categories/fetchCategory', { id })
        .then(response => {
          this.isEditCategorySidebarActive = true
          const imagePopularHeader = []
          const imagePopularCarousel = []
          const imageSolutionHeader = []
          const imageSolutionCarousel = []
          if (response.body.pp_carousel) {
            imagePopularCarousel.push(response.body.pp_carousel)
          }
          if (response.body.pp_header) {
            imagePopularHeader.push(response.body.pp_header)
          }
          if (response.body.sl_header) {
            imageSolutionHeader.push(response.body.sl_header)
          }
          if (response.body.sl_carousel) {
            imageSolutionCarousel.push(response.body.sl_carousel)
          }
          this.categoryData = {
            region_id: response.body.region_id,
            id: response.body.id,
            name: response.body.name,
            second_name: response.body.second_name,
            slug: response.body.slug,
            second_slug: response.body.second_slug,
            description: response.body.description,
            imagePopularHeader,
            imagePopularCarousel,
            imageSolutionHeader,
            imageSolutionCarousel,
          }
          if(response.body.metadata){
            this.currentArrayDescription = response.body.metadata
            this.currentCountDescription = response.body.metadata.length
          }else{
            this.currentArrayDescription = [
                {
                region_id:  {
                label: 'English',
                value: 'en',
                region: 'my',
              },
                name: '',
                description: '',
              },
            ]
            this.currentCountDescription = 1
          }
         
        })
    },
  },

  setup() {
    const currentArrayDescription = ref([])
    const currentCountDescription = ref(0)
    const target = ref('')
    const actor = ref('')
    const isShowModalTracking = ref(false)
    const isAddNewCategorySidebarActive = ref(false)
    const isEditCategorySidebarActive = ref(false)
    const categoryData = {
      name: '',
      second_name: '',
      slug: '',
      second_slug: '',
    }
    const {
      timeout,
      showTable,
      fetchCategory,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryTable,
      refetchData,
    } = userCategoryList()
    return {
      timeout,
      showTable,
      target,
      actor,
      isShowModalTracking,
      categoryData,
      // Sidebar
      isEditCategorySidebarActive,
      isAddNewCategorySidebarActive,
      fetchCategory,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryTable,
      refetchData,

      // Filter
      avatarText,
      currentArrayDescription,
      currentCountDescription
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
