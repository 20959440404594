<template>
  <b-sidebar
    id="add-new-category-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Category
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="categoryData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Upskill"
                @input.native="onChangeTitle"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Second Popular categories name -->
          <!-- <b-form-group
            label="Description"
            label-for="secondName"
          >
            <b-form-input
              id="secondName"
              v-model="categoryData.second_name"
              autofocus
              trim
              placeholder="Upskill"
              @input.native="onChangeTitleSecond"
            />
          </b-form-group> -->
          <!-- Slug -->
          <validation-provider
            #default="validationContext"
            name="Popular categories slug"
            rules="required"
          >
            <b-form-group
              label="Popular categories slug"
              label-for="slug"
            >
              <b-form-input
                id="slug"
                v-model="categoryData.slug"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Slug"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Second Slug -->
          <!-- <b-form-group
            label="Solutions to slug"
            label-for="secondSlug"
          >
            <b-form-input
              id="secondSlug"
              v-model="categoryData.second_slug"
              autofocus
              trim
              placeholder="Upskill"
            />
          </b-form-group> -->
          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="categoryData.description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Description"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Region -->
          <validation-provider
            #default="validationContext"
            name="Region"
            rules="required"
          >
            <b-form-group
              label="Region"
              label-for="region"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="categoryData.region_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regionOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="region"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Transalate -->
            <label for="description-form">Options for translate each region</label>
            <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="0"
            >
                <div
                  v-for="index in countDescription"
                  :key="index"
                >
                  <!-- repeater from -->
                  <b-col cols="12" class="mb-2">
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayDescription.length > 1 && index !== 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Language -->
                        <b-col md="12">
                          <b-form-group
                            label="Language"
                            label-for="product-add-region"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="region-edit-region"
                                v-model="arrayDescription[index - 1].region_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="languageOptions"
                                :clearable="false"
                                :disabled="index === 1"
                                :selectable="(option) => {
                                  return !arrayDescription.find(x => x.region_id.value === option.value)
                                }"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Name -->
                        <b-col md="12">
                          <b-form-group
                            label="Name"
                            label-for="product-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Name"
                              rules="required"
                            >
                              <b-form-input
                                id="product-add-title"
                                v-model="arrayDescription[index - 1].name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Description -->
                        <b-col md="12">
                          <b-form-group
                            label="Description"
                            label-for="product-add-Summary"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Description"
                              rules="required"
                            >
                              <b-form-input
                                id="product-add-Summary"
                                v-model="arrayDescription[index - 1].description"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </div>
              </transition-group>
            <b-col
              cols="12"
              class="mt-2"
              >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormDescription"
              >
                Add description form
              </b-button>
            </b-col>
          <!-- Popular categories header -->
          <!-- <b-form-group
            label="Popular categories header"
            label-for="product-add-region"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Popular categories header"
              rules="required"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="isShowModalPopularHeaderImage =! isShowModalPopularHeaderImage"
              >
                Select image
              </b-button>
              <div class="border rounded p-2 pb-0 mt-2">
                <h6 v-if="categoryData.imagePopularHeader.length === 0">
                  No photos added yet
                </h6>
                <swiper
                  v-if="categoryData.imagePopularHeader.length > 0"
                  v-model="categoryData.imagePopularHeader"
                  class="swiper-responsive-breakpoints"
                  :options="swiperOptions"
                >
                  <swiper-slide
                    v-for="media in categoryData.imagePopularHeader"
                    :key="media.id"
                  >
                    <b-link>
                      <div class="img-container mx-auto">
                        <b-img
                          center
                          :src="media.link"
                          height="100"
                        />
                      </div>
                    </b-link>
                  </swiper-slide>
                </swiper>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>

          </b-form-group> -->
          <!-- Popular categories carousel  -->
          <b-form-group
            label="Popular categories carousel"
            label-for="product-add-title"
            class="mb-2 mt-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Popular categories carousel"
              rules="required"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="isShowModalPopularCarouselImage =! isShowModalPopularCarouselImage"
              >
                Select image
              </b-button>
              <div class="border rounded p-2 pb-0 mt-2">
                <h6 v-if="categoryData.imagePopularCarousel.length === 0">
                  No photos added yet
                </h6>
                <swiper
                  v-if="categoryData.imagePopularCarousel.length > 0"
                  v-model="categoryData.imagePopularCarousel"
                  class="swiper-responsive-breakpoints px-4 py-2"
                  :options="swiperOptions"
                >
                  <swiper-slide
                    v-for="media in categoryData.imagePopularCarousel"
                    :key="media.id"
                  >
                    <b-link>
                      <div class="img-container">
                        <b-img
                          :src="media.link"
                          height="100"
                          center
                        />
                      </div>
                    </b-link>
                  </swiper-slide>
                </swiper>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Solution to header -->
          <!-- <b-form-group
            label="Solution to header"
            label-for="product-add-region"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Solution to header"
              rules="required"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="isShowModalSolutionHeaderImage =! isShowModalSolutionHeaderImage"
              >
                Select image
              </b-button>
              <div class="border rounded p-2 pb-0 mt-2">
                <h6 v-if="categoryData.imageSolutionHeader.length === 0">
                  No photos added yet
                </h6>
                <swiper
                  v-if="categoryData.imageSolutionHeader.length > 0"
                  v-model="categoryData.imageSolutionHeader"
                  class="swiper-responsive-breakpoints"
                  :options="swiperOptions"
                >
                  <swiper-slide
                    v-for="media in categoryData.imageSolutionHeader"
                    :key="media.id"
                  >
                    <b-link>
                      <div class="img-container mx-auto">
                        <b-img
                          center
                          :src="media.link"
                          height="100"
                        />
                      </div>
                    </b-link>
                  </swiper-slide>
                </swiper>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <!-- Solution to carousel  -->
          <!-- <b-form-group
            label="Solution to carousel"
            label-for="product-add-title"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Solution to carousel"
              rules="required"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="isShowModalSolutionCarouselImage =! isShowModalSolutionCarouselImage"
              >
                Select image
              </b-button>
              <div class="border rounded p-2 pb-0 mt-2">
                <h6 v-if="categoryData.imageSolutionCarousel.length === 0">
                  No photos added yet
                </h6>
                <swiper
                  v-if="categoryData.imageSolutionCarousel.length > 0"
                  v-model="categoryData.imageSolutionCarousel"
                  class="swiper-responsive-breakpoints px-4 py-2"
                  :options="swiperOptions"
                >
                  <swiper-slide
                    v-for="media in categoryData.imageSolutionCarousel"
                    :key="media.id"
                  >
                    <b-link>
                      <div class="img-container">
                        <b-img
                          :src="media.link"
                          height="100"
                          center
                        />
                      </div>
                    </b-link>
                  </swiper-slide> -->
          <!-- Add Arrows -->
          <!-- </swiper> -->
          <!-- </div> -->
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
          <!-- </b-form-group> -->
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
      <select-carousel-modal
        :is-show-modal-select-images.sync="isShowModalPopularCarouselImage"
        :selected-box="categoryData.imagePopularCarousel"
        @set-images-id="setImagePopularCarousel"
      />
    </template>
  </b-sidebar>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import {
  BSidebar, BForm, BFormGroup, 
  BFormInput, BFormInvalidFeedback, 
  BButton, BLink, BImg, BCol, BCardBody, BRow
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as utils from '@/utils/utils'
import SelectImageModal from '@/views/modal/SelectImageModal.vue'
import SelectCarouselModal from '@/views/modal/SelectCarouselModal.vue'
import * as config from '@/utils/config'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BCol,
    BRow,
    BImg,
    BLink,
    SelectCarouselModal,
    SelectImageModal,
    Swiper,
    SwiperSlide,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCardBody,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCategorySidebarActive',
    event: 'update:is-add-new-category-sidebar-active',
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['regionOptions', 'languageOptions']),
  },
  methods: {
    addNewItemInItemFormDescription() {
      this.arrayDescription.push({
        name: '',
        description: '',
        region_id: '',
      })
      this.countDescription += 1
    },
    removeItemDescription(index) {
      this.arrayDescription.splice(index, 1)
      this.countDescription -= 1
    },
    onChangeTitle() {
      this.categoryData.slug = utils.toSlug(this.categoryData.name)
    },
    onChangeTitleSecond() {
      this.categoryData.second_slug = utils.toSlug(this.categoryData.second_name)
    },
    setImagePopularHeader(arrayID) {
      this.categoryData.imagePopularHeader = arrayID
    },
    setImagePopularCarousel(arrayID) {
      this.categoryData.imagePopularCarousel = arrayID
    },
    setImageSolutionHeader(arrayID) {
      this.categoryData.imageSolutionHeader = arrayID
    },
    setImageSolutionCarousel(arrayID) {
      this.categoryData.imageSolutionCarousel = arrayID
    },
  },
  setup(props, { emit }) {
    const countDescription = ref(1)
    const arrayDescription =  ref([
          {
          region_id:  {
          label: 'English',
          value: 'en',
          region: 'my',
        },
          name: '',
          description: '',
        },
      ])
    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    }
    const isShowModalPopularHeaderImage = ref(false)
    const isShowModalPopularCarouselImage = ref(false)
    const isShowModalSolutionHeaderImage = ref(false)
    const isShowModalSolutionCarouselImage = ref(false)
    const toast = useToast()
    const imagePopularHeader = ref([])
    const imagePopularCarousel = ref([])
    const imageSolutionHeader = ref([])
    const imageSolutionCarousel = ref([])
    const blankCategory = {
      imagePopularHeader: [],
      imagePopularCarousel: [],
      imageSolutionHeader: [],
      imageSolutionCarousel: [],
      name: '',
      second_name: '',
      slug: '',
      second_slug: '',
    }
    const categoryData = ref(JSON.parse(JSON.stringify(blankCategory)))
    const resetuserData = () => {
      categoryData.value = JSON.parse(JSON.stringify(blankCategory))
    }
    const hideModal = () => {
      emit('refetch-data')
    }
    const onSubmit = () => {
      const transferData = {
        name: categoryData.value.name,
        slug: `${categoryData.value.slug}-${categoryData.value.region_id}`,
        // second_name: categoryData.value.name,
        // second_slug: `${categoryData.value.second_slug}-${categoryData.value.region_id}`,
        description: categoryData.value.description,
        pp_carousel_id: categoryData.value.imagePopularCarousel[0].id,
        // pp_header_id: categoryData.value.imagePopularHeader[0].id,
        // sl_carousel_id: categoryData.value.imageSolutionCarousel[0].id,
        // sl_header_id: categoryData.value.imageSolutionHeader[0].id,
        region_id: categoryData.value.region_id,
        metadata: arrayDescription.value
      }
      store.dispatch('categories/addCategories', transferData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-category-sidebar-active', false)
        }).catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      swiperOptions,
      isShowModalPopularHeaderImage,
      isShowModalPopularCarouselImage,
      isShowModalSolutionHeaderImage,
      isShowModalSolutionCarouselImage,
      toast,
      categoryData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      hideModal,
      config,
      imagePopularHeader,
      imagePopularCarousel,
      imageSolutionHeader,
      imageSolutionCarousel,
      countDescription,
      arrayDescription
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-category-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}


</style>
