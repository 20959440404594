import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import {
  useToast,
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function userCategoryList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refCategoryTable = ref(null)

  // Table Handlers
  const tableColumns = [{
    key: 'name',
    sortable: false,
    label: 'name',
  },
  // {
  //   key: 'second_name',
  //   label: 'name',
  //   sortable: false,
  // },

  {
    key: 'slug',
    label: 'slug',
    sortable: false,
  },
  // {
  //   key: 'second_slug',
  //   label: 'Solutions to slug',
  //   sortable: false,
  // },
  // {
  //   key: 'description',
  //   label: 'Description',
  //   sortable: false,
  // },
  {
    key: 'region_id',
    label: 'Region',
    sortable: false,
  },
  {
    key: 'created_at',
    sortable: false,
  },
  {
    key: 'action',
  },
  ]
  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const outOfStockFilter = ref(null)
  const activeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCategoryTable.value
      ? refCategoryTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchData = () => {
    refCategoryTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
    ],
    () => {
      refetchData()
    },
  )
  const fetchCategory = (ctx, callback) => {
    store
      .dispatch('product/fetchCategoriesList', {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        order_by: 'created_at',
        order_type: 'desc',
      })
      .then(response => {
        const categories = response.body.data
        totalProducts.value = response.body.total
        callback(categories)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching products list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
    ],
    () => {
      showTable.value = true
           clearTimeout(timeout.value)

      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchCategory,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategoryTable,
    refetchData,
    // Extra
  }
}
